var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { inert: _vm.readOnly },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.changeState()
        },
      },
    },
    [_c("div", { style: _vm.appliedStyle })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }