<template>
  <v-container :class="{ 'pa-0': screenSize < 600 }">
    <page-title
      :page-title="!$language ? 'Bulk Enrollment' : 'বাল্ক এনরোলমেন্ট'"
      previous-location
    ></page-title>
    <v-sheet :width="sizes.sheet.width" :class="[classes.sheet.class]">
      <v-row no-gutters>
        <v-col
          v-if="fetchingCourses"
          :cols="sizes.innerCol.colsSelect"
          class="d-flex justify-center align-center"
          style="min-height: 100px"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col v-else :cols="sizes.innerCol.colsSelect">
          <!--          <v-text-field-->
          <!--            id="bulk-enroll-search-box"-->
          <!--            outlined-->
          <!--            :hide-details="true"-->
          <!--            class="mb-3 white"-->
          <!--            dense-->
          <!--            v-model="filterKey"-->
          <!--            @keyup.enter="courseKey = filterKey"-->
          <!--          >-->
          <!--            <template #prepend-inner>-->
          <!--              <v-icon>search</v-icon>-->
          <!--            </template>-->
          <!--          </v-text-field>-->
          <div
            :style="
              `height: ${sizes.panel.height}px; overflow-x: hidden; overflow-y: auto`
            "
          >
            <v-expansion-panels flat :value="openCourse">
              <v-expansion-panel
                v-for="(item, index) in courses"
                :key="index"
                class="mb-1 border border-solid border-default"
                style="border-radius: 6px"
                readonly
              >
                <v-card flat>
                  <v-row no-gutters>
                    <v-col
                      class="d-flex align-center justify-start pa-3"
                      cols="6"
                    >
                      <m-check-box
                        circular
                        :radio="selectedCourse"
                        :value="index"
                        @click="courseSelect"
                      ></m-check-box>
                      <div class="ml-2 text-truncate md:text-sm sm:text-sm">
                        {{ item.title }}
                      </div>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-end pa-3"
                      cols="6"
                    >
                      <span
                        class="ft-grey xl:text-sm lg:text-sm md:text-xs sm:text-xs"
                        >{{ item.batches }}
                        {{ !$language ? "batch" : "টি ব্যাচ" }}</span
                      ><span
                        v-if="item.batches > 1 && !$language"
                        class="ft-grey"
                        >es</span
                      >
                      <v-btn
                        icon
                        :disabled="item.batches === 0"
                        @click="expandPanel(index)"
                      >
                        <v-icon
                          :class="[
                            'arrow-normal',
                            { 'arrow-rotated': item.active }
                          ]"
                          >mdi-chevron-down
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
                <v-expansion-panel-content
                  v-if="item.batches > 0 && item.content.length > 0"
                  class="panel-content"
                >
                  <v-card flat class="border border-solid border-default pa-2">
                    <v-row
                      v-for="(batch, j) in item.content"
                      :key="j"
                      no-gutters
                    >
                      <v-col
                        cols="6"
                        class="px-2 py-1 d-flex align-center justify-start"
                      >
                        <m-check-box
                          circular
                          :size="15"
                          :status="satisfyCondition(index, j)"
                          @click="insertBatch(index, j)"
                        ></m-check-box>
                        <div
                          class="ml-2 text-truncate xl:text-sm lg:text-sm md:text-xs sm:text-xs"
                        >
                          {{ batch.title }}
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        class="px-2 py-1 d-flex align-center justify-end"
                      >
                        <span
                          class="ft-grey xl:text-xs lg:text-xs md:text-xxs sm:text-xxs"
                          >{{ batch.students }}
                          {{ !$language ? "student" : "জন শিক্ষার্থী" }}</span
                        >
                        <span
                          v-if="batch.students > 1 && !$language"
                          class="ft-grey xl:text-xs lg:text-xs md:text-xxs sm:text-xxs"
                          >s</span
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else>
                  <v-progress-circular></v-progress-circular>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col
          :cols="sizes.innerCol.colsProgress"
          :class="classes.innerCol.colsProgress"
        >
          <v-row
            no-gutters
            class="d-flex align-center justify-center"
            style="height: 80%"
          >
            <v-sheet
              :class="[
                'border border-solid border-default',
                screenSize < 600 ? 'pa-0' : 'py-3 px-5 pt-4'
              ]"
              style="border-radius: 6px; width: 100%"
            >
              <h4 v-show="screenSize >= 600" class="mb-4">
                {{
                  !$language
                    ? "Create Bulk Enrollment"
                    : "বাল্ক এনরোলমেন্ট করুন"
                }}
              </h4>
              <v-card style="width: 100%">
                <h5 :class="['pa-5', { 'text-center': screenSize < 600 }]">
                  {{
                    !$language
                      ? "Import your bulk enrollment file"
                      : "ভর্তির তালিকা (ফাইল) এখানে প্রদান করুন"
                  }}
                </h5>
                <div
                  style="width: 100%"
                  class="d-flex flex-column justify-center align-center"
                >
                  <!-- <v-btn depressed color="#0099dc" class="white--text">
                  <v-icon class="mr-2" style="transform: rotate(45deg)">mdi-paperclip</v-icon>
                  <span>Attach File</span>
                </v-btn> -->
                  <v-file-input
                    v-model="enrollFile"
                    outlined
                    dense
                    :style="
                      `width: ${screenSize < 600 ? 87 : 70}%; ${
                        screenSize < 600 ? 'padding-right: 2%' : ''
                      }`
                    "
                    :placeholder="
                      !$language ? 'Attach file' : 'ফাইল সংযোগ করুন'
                    "
                  >
                  </v-file-input>
                  <!-- <p><b>Note: </b></p> -->
                  <div
                    :class="[
                      classes.instruction,
                      'xl:text-sm lg:text-xs md:text-xs sm:text-xxs'
                    ]"
                    style="text-align: justify"
                  >
                    <div v-if="!$language">
                      <span class="ft-medium mb-2">Instructions:</span>
                      <ul>
                        <li>
                          The file you upload must be an Excel file
                          (.xlsx/.xlsm).
                        </li>
                        <li>
                          Your data must start from the first row with no empty
                          row in between data rows.
                        </li>
                        <li>The first row in your file must be the header.</li>
                        <li>
                          Your header row must contains columns
                          <b><i>Name</i></b
                          >, <b><i>Email</i></b
                          >, <b><i>Phone</i></b> (the case and order is not
                          important).
                        </li>
                        <li>
                          The name you provide must be between 5 and 64 letters
                          in lenght and can only contain alpha-numeric (a-z A-Z
                          0-9), dots(.), colons(:), hyphens(-), quotes(' ") and
                          spaces .
                        </li>
                        <li>
                          The emails & phone numbers you provided must have
                          correct formatting.
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      <span class="ft-medium mb-2">দিক নির্দেশনা:</span>
                      <ul>
                        <li>একটি এক্সেল ফাইল প্রদান করুন (.xlsx/.xlsm)।</li>
                        <li>
                          শিক্ষার্থীদের তথ্য সমূহ প্রথম সারি থেকে আরম্ভ হতে হবে।
                        </li>
                        <li>
                          প্রথম সারিতে অবশ্যই কলাম এর নাম সমূহ থাকবে এবং মাঝে
                          কোনো খালি সারি থাকা উচিত নয়।
                        </li>
                        <li>
                          কলাম এর নাম হিসেবে
                          <b><i>Name</i></b
                          >, <b><i>Email</i></b
                          >, <b><i>Phone</i></b> থাকবে (ক্রম এবং বড় বা ছোট হাতের
                          অক্ষর উদাহরণ এর মতো হওয়া জরুরী নয়)।
                        </li>
                        <li>
                          কলামের নাম সমূহ ৫ থেকে ৬৪ অক্ষর এর হতে হবে এবং
                          বিন্দু(.), হাইফেন(-), কোলন(:), উদ্ধৃতি(' ") এবং
                          শূন্যস্থান থাকতে পারবে।
                        </li>
                        <li>ইমেইল এবং ফোন নম্বর সঠিক হতে হবে।</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-sheet>
          </v-row>
          <v-row
            v-if="!(enrollFile === undefined)"
            no-gutters
            style="height: 20%"
          >
            <v-col
              cols="12"
              :class="[
                'd-flex align-end justify-end',
                screenSize < 600 ? 'pa-0 pt-2' : 'pa-5 pb-0'
              ]"
            >
              <v-btn
                :loading="processing"
                color="#0099dc"
                class="ft-avenir white--text"
                @click="upload()"
                >{{ !$language ? "Upload File" : "জমা দিন" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { getOwnCourses } from "#ecf/courses/actions.js";
import MCheckBox from "~ecf/components/MCheckBox";

export default {
  name: "BulkEnrollmentPage",
  components: { MCheckBox },
  props: {
    partner_code: String
  },
  data() {
    return {
      fetchingCourses: undefined,
      processing: false,
      selectedCourse: undefined,
      openCourse: undefined,
      fetchingBatches: undefined,
      enrollFile: undefined,
      courses: [],
      batches: {},
      filterKey: undefined,
      courseKey: undefined
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    sizes() {
      let s = this.screenSize;
      let h = this.$vuetify.breakpoint.height;
      return {
        sheet: {
          width: s < 600 ? "100%" : s < 960 ? 500 : s < 1600 ? 960 : 1024
        },
        innerCol: {
          colsSelect: s < 960 ? "12" : "5",
          colsProgress: s < 960 ? "12" : "7"
        },
        panel: {
          height:
            s < 600
              ? Math.ceil(0.42 * h)
              : s < 960
              ? Math.ceil(0.54 * h)
              : s < 1600
              ? Math.ceil(0.55 * h)
              : Math.ceil(0.67 * h)
        }
      };
    },
    classes() {
      let s = this.screenSize;
      return {
        sheet: {
          class: s < 600 ? "transparent pa-0" : "white pa-6"
        },
        innerCol: {
          colsProgress: s < 600 ? "mt-5" : s < 960 ? "mt-10" : "pl-10"
        },
        instruction: s < 600 ? "pa-3 pr-5" : "pa-5 pr-7"
      };
    },
    noFilterCondition() {
      return this.courseKey === undefined || this.courseKey.trim().length == 0;
    },
    filteredCourses() {
      if (this.noFilterCondition) return this.courses;
      return this.courses.filter(item =>
        item.title.toLowerCase().includes(this.courseKey.toLowerCase())
      );
    }
  },
  async created() {
    await this.fetchCourses();
  },
  methods: {
    async expandPanel(index) {
      if (this.fetchingBatches) return;
      if (
        this.courses[index].content.length === 0 &&
        this.courses[index].batches > 0
      ) {
        await this.fetchBatches(index);
      }
      if (this.openCourse !== index) {
        if (!(this.openCourse === undefined))
          this.courses[this.openCourse].active = false;
        this.openCourse = index;
      } else {
        this.openCourse = undefined;
      }
      this.courses[index].active = !this.courses[index].active;
    },
    courseSelect(value) {
      if (!(this.selectedCourse === undefined))
        this.$delete(this.batches, this.selectedCourse);
      if (this.selectedCourse === value) this.selectedCourse = undefined;
      else this.selectedCourse = value;
      if (!(this.selectedCourse === undefined))
        this.$set(this.batches, this.selectedCourse, {0: true});
    },
    insertBatch(parent, value) {
      if (!(parent in this.batches)) this.courseSelect(parent);
      if (value in this.batches[parent])
        this.$delete(this.batches[parent], value);
      else {
        let data = {};
        data[value] = true;
        this.$set(this.batches, parent, data);
      }
    },
    satisfyCondition(parent, value) {
      if (!(parent in this.batches)) return false;
      else {
        return value in this.batches[parent];
      }
    },
    async fetchCourses() {
      try {
        this.fetchingCourses = true;
        const courseList = await getOwnCourses(this.$store);
        for (let item of courseList) {
          this.courses.push({
            id: item.course_id,
            title: item.course_title,
            batches: item.num_batches,
            active: false,
            content: []
          });
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingCourses = false;
      }
    },
    async fetchBatches(index) {
      try {
        this.fetchingBatches = true;
        const batchList = await this.$store.dispatch("batch/list", {
          courseId: this.courses[index].id,
          partner_code: this.partner_code
        });
        for (let item of batchList) {
          this.courses[index].content.push({
            id: item.batch_id,
            title: item.batch_title,
            students: item.num_students
          });
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingBatches = false;
        if (this.selectedCourse === index) {
          this.$set(this.batches, index, { 0: true });
        }
      }
    },
    // async enroll() {
    //   try {
    //     this.enrolling = true;
    //     if (this.studentIds.length === 0) {
    //       throw new Error("At least one student should be selected");
    //     }
    //     if (this.batches.length === 0) {
    //       throw new Error("At least one batch should be selected");
    //     }
    //     await this.$store.dispatch("course/bulkEnroll", {
    //       studentIds: this.studentIds,
    //       batches: this.batches,
    //       courseId: this.courses[0].course_id,
    //     });
    //     this.$root.$emit("alert", [
    //       "Success",
    //       !this.$language
    //         ? "Students enrolled in batches"
    //         : "শিক্ষার্থীদের ব্যাচে এনরোল করা হয়েছে",
    //     ]);
    //   } catch (e) {
    //     this.$root.$emit("alert", [undefined, e.message]);
    //   } finally {
    //     this.enrolling = false;
    //   }
    // },
    async upload() {
      if (this.processing) return;
      try {
        this.processing = true;
        let batchList = [];
        for (let item of Object.keys(this.batches[this.selectedCourse])) {
          batchList.push(this.courses[this.selectedCourse].content[item].id);
        }
        let resp = await this.$store.dispatch("course/bulkEnrollv2", {
          fileName: this.enrollFile.name,
          batches: batchList,
          courseId: this.courses[this.selectedCourse].id,
          partnerCode: this.$store.state.user.user.last_partner_code
        });
        let policy = resp.upload_info;
        let presignedKey = policy.fields.key;

        await this.$store.dispatch("course/uploadEnrollFile", {
          policy: policy,
          fileBlob: this.enrollFile
        });

        let resp2 = await this.$store.dispatch("course/bulkEnrollv2", {
          fileName: this.enrollFile.name,
          batches: batchList,
          courseId: this.courses[this.selectedCourse].id,
          partnerCode: this.$store.state.user.user.last_partner_code,
          presignedKey: presignedKey
        });

        await this.$store.dispatch("course/bulkEnrollForward", resp2);
        this.$root.$emit("alert", ["Success", "you file is being processed"]);
        this.$router.go();
      } catch (e) {
        if (Object.keys(this.batches).length < 1)
          this.$root.$emit("alert", [
            undefined,
            !this.$language
              ? "You must select at least one batch"
              : "কোনো ব্যাচ নির্বাচন করা হয় নি"
          ]);
        else this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.arrow-normal {
  transition: transform 0.25s ease-in;
}

.arrow-rotated {
  transform: rotate(180deg);
}

.panel-content::v-deep .v-expansion-panel-content__wrap {
  @media screen and (max-width: 599px) {
    padding: 12px;
  }
}
</style>
