<template>
  <div :class="{ inert: readOnly }" @click.stop="changeState()">
    <div :style="appliedStyle"></div>
  </div>
</template>

<script>
export default {
  name: "MCheckBox",
  props: {
    rounded: {
      type: Boolean,
      default: false
    },
    circular: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#0099fc"
    },
    size: {
      type: Number,
      default: 20
    },
    status: {
      type: Boolean,
      default: undefined
    },
    value: {
      type: [Number, String, Object],
      default: undefined
    },
    radio: {
      type: Number,
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: this.status === undefined ? false : this.status
    };
  },
  computed: {
    appliedCurvature() {
      return this.circular ? this.size / 2 : this.rounded ? 6 : 0;
    },
    appliedStyle() {
      return this.active
        ? `height: ${this.size}px; width: ${this.size}px; background-color: ${this.color};border-radius: ${this.appliedCurvature}px`
        : `height: ${this.size}px; width: ${this.size}px; background-color: white;border-radius: ${this.appliedCurvature}px; border: 1px solid ${this.color}`;
    }
  },
  watch: {
    radio: {
      handler(v) {
        if (v === undefined) return;
        else if (v == this.value) this.active = true;
        else this.active = false;
      }
    },
    status: {
      handler(v) {
        this.active = v;
      }
    }
  },
  methods: {
    changeState() {
      this.active = !this.active;
      this.$emit("click", this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.box-class {
  height: 20px;
  width: 20px;
  // background-color: $orange;
}
</style>
