var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: { "pa-0": _vm.screenSize < 600 } },
    [
      _c("page-title", {
        attrs: {
          "page-title": !_vm.$language ? "Bulk Enrollment" : "বাল্ক এনরোলমেন্ট",
          "previous-location": "",
        },
      }),
      _c(
        "v-sheet",
        {
          class: [_vm.classes.sheet.class],
          attrs: { width: _vm.sizes.sheet.width },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _vm.fetchingCourses
                ? _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center align-center",
                      staticStyle: { "min-height": "100px" },
                      attrs: { cols: _vm.sizes.innerCol.colsSelect },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { attrs: { cols: _vm.sizes.innerCol.colsSelect } },
                    [
                      _c(
                        "div",
                        {
                          style: `height: ${_vm.sizes.panel.height}px; overflow-x: hidden; overflow-y: auto`,
                        },
                        [
                          _c(
                            "v-expansion-panels",
                            { attrs: { flat: "", value: _vm.openCourse } },
                            _vm._l(_vm.courses, function (item, index) {
                              return _c(
                                "v-expansion-panel",
                                {
                                  key: index,
                                  staticClass:
                                    "mb-1 border border-solid border-default",
                                  staticStyle: { "border-radius": "6px" },
                                  attrs: { readonly: "" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-start pa-3",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("m-check-box", {
                                                attrs: {
                                                  circular: "",
                                                  radio: _vm.selectedCourse,
                                                  value: index,
                                                },
                                                on: { click: _vm.courseSelect },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 text-truncate md:text-sm sm:text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-end pa-3",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ft-grey xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.batches) +
                                                      " " +
                                                      _vm._s(
                                                        !_vm.$language
                                                          ? "batch"
                                                          : "টি ব্যাচ"
                                                      )
                                                  ),
                                                ]
                                              ),
                                              item.batches > 1 && !_vm.$language
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ft-grey" },
                                                    [_vm._v("es")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    disabled:
                                                      item.batches === 0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.expandPanel(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class: [
                                                        "arrow-normal",
                                                        {
                                                          "arrow-rotated":
                                                            item.active,
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-down "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.batches > 0 && item.content.length > 0
                                    ? _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "panel-content" },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "border border-solid border-default pa-2",
                                              attrs: { flat: "" },
                                            },
                                            _vm._l(
                                              item.content,
                                              function (batch, j) {
                                                return _c(
                                                  "v-row",
                                                  {
                                                    key: j,
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "px-2 py-1 d-flex align-center justify-start",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c("m-check-box", {
                                                          attrs: {
                                                            circular: "",
                                                            size: 15,
                                                            status:
                                                              _vm.satisfyCondition(
                                                                index,
                                                                j
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.insertBatch(
                                                                index,
                                                                j
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ml-2 text-truncate xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  batch.title
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "px-2 py-1 d-flex align-center justify-end",
                                                        attrs: { cols: "6" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ft-grey xl:text-xs lg:text-xs md:text-xxs sm:text-xxs",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                batch.students
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  !_vm.$language
                                                                    ? "student"
                                                                    : "জন শিক্ষার্থী"
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        batch.students > 1 &&
                                                        !_vm.$language
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ft-grey xl:text-xs lg:text-xs md:text-xxs sm:text-xxs",
                                                              },
                                                              [_vm._v("s")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-expansion-panel-content",
                                        [_c("v-progress-circular")],
                                        1
                                      ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
              _c(
                "v-col",
                {
                  class: _vm.classes.innerCol.colsProgress,
                  attrs: { cols: _vm.sizes.innerCol.colsProgress },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex align-center justify-center",
                      staticStyle: { height: "80%" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-sheet",
                        {
                          class: [
                            "border border-solid border-default",
                            _vm.screenSize < 600 ? "pa-0" : "py-3 px-5 pt-4",
                          ],
                          staticStyle: {
                            "border-radius": "6px",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "h4",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.screenSize >= 600,
                                  expression: "screenSize >= 600",
                                },
                              ],
                              staticClass: "mb-4",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "Create Bulk Enrollment"
                                      : "বাল্ক এনরোলমেন্ট করুন"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-card", { staticStyle: { width: "100%" } }, [
                            _c(
                              "h5",
                              {
                                class: [
                                  "pa-5",
                                  { "text-center": _vm.screenSize < 600 },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language
                                        ? "Import your bulk enrollment file"
                                        : "ভর্তির তালিকা (ফাইল) এখানে প্রদান করুন"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-center align-center",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _c("v-file-input", {
                                  style: `width: ${
                                    _vm.screenSize < 600 ? 87 : 70
                                  }%; ${
                                    _vm.screenSize < 600
                                      ? "padding-right: 2%"
                                      : ""
                                  }`,
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    placeholder: !_vm.$language
                                      ? "Attach file"
                                      : "ফাইল সংযোগ করুন",
                                  },
                                  model: {
                                    value: _vm.enrollFile,
                                    callback: function ($$v) {
                                      _vm.enrollFile = $$v
                                    },
                                    expression: "enrollFile",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.classes.instruction,
                                      "xl:text-sm lg:text-xs md:text-xs sm:text-xxs",
                                    ],
                                    staticStyle: { "text-align": "justify" },
                                  },
                                  [
                                    !_vm.$language
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "ft-medium mb-2" },
                                            [_vm._v("Instructions:")]
                                          ),
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                " The file you upload must be an Excel file (.xlsx/.xlsm). "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " Your data must start from the first row with no empty row in between data rows. "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                "The first row in your file must be the header."
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " Your header row must contains columns "
                                              ),
                                              _c("b", [
                                                _c("i", [_vm._v("Name")]),
                                              ]),
                                              _vm._v(", "),
                                              _c("b", [
                                                _c("i", [_vm._v("Email")]),
                                              ]),
                                              _vm._v(", "),
                                              _c("b", [
                                                _c("i", [_vm._v("Phone")]),
                                              ]),
                                              _vm._v(
                                                " (the case and order is not important). "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " The name you provide must be between 5 and 64 letters in lenght and can only contain alpha-numeric (a-z A-Z 0-9), dots(.), colons(:), hyphens(-), quotes(' \") and spaces . "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " The emails & phone numbers you provided must have correct formatting. "
                                              ),
                                            ]),
                                          ]),
                                        ])
                                      : _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "ft-medium mb-2" },
                                            [_vm._v("দিক নির্দেশনা:")]
                                          ),
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                "একটি এক্সেল ফাইল প্রদান করুন (.xlsx/.xlsm)।"
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " শিক্ষার্থীদের তথ্য সমূহ প্রথম সারি থেকে আরম্ভ হতে হবে। "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " প্রথম সারিতে অবশ্যই কলাম এর নাম সমূহ থাকবে এবং মাঝে কোনো খালি সারি থাকা উচিত নয়। "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(" কলাম এর নাম হিসেবে "),
                                              _c("b", [
                                                _c("i", [_vm._v("Name")]),
                                              ]),
                                              _vm._v(", "),
                                              _c("b", [
                                                _c("i", [_vm._v("Email")]),
                                              ]),
                                              _vm._v(", "),
                                              _c("b", [
                                                _c("i", [_vm._v("Phone")]),
                                              ]),
                                              _vm._v(
                                                " থাকবে (ক্রম এবং বড় বা ছোট হাতের অক্ষর উদাহরণ এর মতো হওয়া জরুরী নয়)। "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                " কলামের নাম সমূহ ৫ থেকে ৬৪ অক্ষর এর হতে হবে এবং বিন্দু(.), হাইফেন(-), কোলন(:), উদ্ধৃতি(' \") এবং শূন্যস্থান থাকতে পারবে। "
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                "ইমেইল এবং ফোন নম্বর সঠিক হতে হবে।"
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !(_vm.enrollFile === undefined)
                    ? _c(
                        "v-row",
                        {
                          staticStyle: { height: "20%" },
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              class: [
                                "d-flex align-end justify-end",
                                _vm.screenSize < 600
                                  ? "pa-0 pt-2"
                                  : "pa-5 pb-0",
                              ],
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ft-avenir white--text",
                                  attrs: {
                                    loading: _vm.processing,
                                    color: "#0099dc",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.upload()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Upload File" : "জমা দিন"
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }